<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px">
      <custom-table
        :loading="loading"
        :title="$t('meteo.title', locale)"
        :headers="[
          { text: $t('meteo.name', locale), value: 'ident' },
          { text: $t('meteo.zone', locale), value: 'Zone' },
          { text: $t('meteo.URL', locale), value: 'URL' },
          { text: 'Coordenadas', value: 'latLon' },
          { text: $t('meteo.turismo', locale), value: 'check', align: 'center' },
        ]"
        :onGetData="handleGetData"
        :tableData="zones"
        :onSearch="handleSearch"
        :onEditItem="handleEdit"
        :onDeleteItem="handleDelete"
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elekvation="0"
      >
        <v-card-text>
          <zone-form
            :editedID="editedID"
            :showForm="showForm"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onClose="handleCloseForm"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import CustomTable from '@/components/customTable/Index'
import ZoneForm from './ZoneForm'
export default {
  components: {
    CustomTable,
    ZoneForm
  },
  data: () => ({
    loading: false,
    zones: [],
    searchValue: '',
    startIndex: 0,
    itemsPerPage: 5,
    count: null,

    showForm: false,

    editedItem: null,
    editedID: null,
  }),
  computed: {
    ...mapState('app', ['locale']),
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleSearch (v) {
      this.searchValue = v
      this.startIndex = 0
      this.handleGetData()
    },
    handleGetData (startIndex) {
      this.zones = []
      this.loading = true
      if (startIndex >=0 ) this.startIndex = startIndex
      api.getAll ('meteo', 'v1/system/meteos/zones/', this.startIndex, this.itemsPerPage, this.searchValue)
        .then(response => {             
          this.count = response.count
          this.zones = response.data.map(item => {
            return {
              ID: item.ID.toString(),
              Name: item.Lugar,
              Zone: item.NombreMun,
              URL: item.URL,
              check: item.Turismo,
              latLon: item.Lat && item.Lon ? `${item.Lat}, ${item.Lon}` : ''
            }
          })
          this.loading = false
        })
    },
    handleEdit (v) {
      this.editedID = v
      this.showForm = true
    },
    handleDelete (v) {
      if (v) 
        api.deleteItem ('meteo', 'v1/system/meteos/zones/', v)
        .then(() => {                      
          this.handleGetData(this.startIndex)
          this.handleCloseForm()
        })
    },
    handleCloseForm () {
      this.showForm = false
    },
    handleSave (v) {
      if (v.ID)
        api.updateItem ('meteo', 'v1/system/meteos/zones/', v.ID, v)
        .then(() => {          
          this.handleGetData(this.startIndex)
          this.handleCloseForm()
        })  
      else
        api.addItem ('meteo', 'v1/system/meteos/zones/', v)
        .then(() => {          
          this.handleGetData(this.startIndex)
          this.handleCloseForm()
        })        
    },
  },
}
</script>

